import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Image,
  Link,
  StyleProps,
  Text,
} from '@chakra-ui/react'

export const SplitView = ({ Left, Right }: { Left: any; Right: any }) => {
  return (
    <Flex minH={400} wrap="wrap">
      <Left flexBasis={{ base: '100%', md: '50%' }} />
      <Right flexBasis={{ base: '100%', md: '50%' }} />
    </Flex>
  )
}

const Job = () => {
  return (
    <>
      <Box>
        <Heading m={10} textAlign="center">
          No coding experience required. Start at $16 / hour.
        </Heading>
        <Text m={10} fontSize="lg">
          Code Correctly has created an innovative{' '}
          <Text as="b">Software Engineering Apprenticeship</Text> program. This
          apprenticeship is a part-time, work-from-home, entry-level job. Code
          Correctly believes that <Text as="b">anyone</Text> that wants to learn
          how to code, <Text as="b">can</Text>.
        </Text>
      </Box>
    </>
  )
}

const Instruction = () => {
  return (
    <>
      <SplitView
        Left={(props: StyleProps) => (
          <Box {...props}>
            <Image src="apprentice.png" maxH="400" m="auto" />
          </Box>
        )}
        Right={(props: StyleProps) => (
          <Box {...props}>
            <Heading m={10} textAlign="center">
              Write code. Don't watch videos.
            </Heading>
            <Text m={10} fontSize="lg">
              You will <Text as="b">learn-by-doing</Text> with an experienced
              software engineer and a small cohort of peers.
            </Text>
          </Box>
        )}
      />
    </>
  )
}

const Projects = () => {
  return (
    <>
      <SplitView
        Left={(props: StyleProps) => (
          <Box {...props}>
            <Heading m={10} textAlign="center">
              Ship production code. No toy projects here.
            </Heading>
            <Text m={10} fontSize="lg">
              After passing strict quality gates, the code you write is used for
              internal and external clients of Code Correctly.
            </Text>
          </Box>
        )}
        Right={(props: StyleProps) => (
          <Box {...props}>
            <Box
              w="400px"
              h="300px"
              m="auto"
              bgColor="#3a3a3a"
              borderRadius="md"
            >
              <Box h="20px" bgColor="#2d9fd8" borderTopRadius="md"></Box>
              <Box color="white" m="2">
                {/* <code>$ ./run.sh</code>
                <code>hsd</code> */}
                <pre>
                  $ ./your_code.sh{'\n'}
                  building client app...done!{'\n'}
                  deploying client app...
                </pre>
              </Box>
            </Box>
          </Box>
        )}
      />
    </>
  )
}

const FAQ = () => {
  return (
    <>
      <Heading textAlign="center" m={10}>
        Frequently Asked Questions
      </Heading>
      <Accordion>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                Is the apprenticeship registered with the US Department of
                Labor?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel>
            No.{' '}
            <Link
              href="https://www.apprenticeship.gov/employers/registered-apprenticeship-program"
              isExternal
            >
              A Registered Apprenticeship <ExternalLinkIcon mx="2px" />
            </Link>{' '}
            lays out specific requirements. This registration process in not
            legally required. Code Correctly chooses to not register because we
            believe the rules of the US Department of Labor were designed around
            traditional trades, e.g. plumbing. The tech industry moves faster
            than the government, and is not conducive to that model.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                How much time do I need to commit?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel>
            While the entirety of the apprenticeship is learn-by-doing, there
            will be two distinct phases. The first phase will be primarily
            learning in a classroom-like environment. Class times will depend on
            your specific cohort, but imagine 2 hours a day, twice a week, for 2
            months. The second phase will be primarily doing, where you work on
            your schedule. But don't worry, your mentor will be available for
            working with you.
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  )
}

const Interested = () => {
  return (
    <>
      <Heading textAlign="center" marginTop={'40px'}>
        Interested?
      </Heading>
      <Text>
        Code Correctly employees must reside in the United States. Due to
        popular demand, spots in Code Correctly's Software Engineering
        Apprenticeship are currently unavailable. This page will be updated if
        and when we have spots available.
      </Text>
    </>
  )
}

export const Apprenticeship = () => {
  return (
    <>
      <Box my={200}>
        <Heading size="4xl" textAlign="center" m={10}>
          <Text as="span">WE PAY YOU</Text> to learn how to code
        </Heading>
        <Text textAlign="center">(yes, really)</Text>
      </Box>

      <Job />
      <Instruction />
      <Projects />
      <Box margin="40px">
        <FAQ />
        <Interested />
      </Box>
      <Box my={100}></Box>
    </>
  )
}
