import {
  Box,
  Flex,
  Image,
  Spacer,
  SystemStyleObject,
  Text,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const headerLinkStyles: SystemStyleObject = {
  marginTop: '20px',
  marginRight: '20px',
  textColor: 'white',
}

export const Header = () => {
  return (
    <>
      <Box backgroundColor={'#1D2939'}>
        <Flex>
          <Link to="/">
            <Image src="cc-logo-name-clear.svg" style={{ margin: '20px' }} />
          </Link>
          <Spacer />
          <Link to="/apprenticeship">
            <Text sx={headerLinkStyles}>Apprenticeship</Text>
          </Link>
          <Link to="/clients">
            <Text sx={headerLinkStyles}>Clients</Text>
          </Link>
        </Flex>
      </Box>
    </>
  )
}
