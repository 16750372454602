import './App.css'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Layout } from './Layout'
import { Apprenticeship } from './Apprenticeship'
import { Clients } from './Clients'
import { Home } from './Home'
import { Privacy } from './Privacy'
import { Terms } from './Terms'
import { Support } from './Support'
import { Fulfillment } from './Fulfillment'

const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        lineHeight: '1.75',
      },
      'h2, h3': {
        paddingTop: '2',
        paddingBottom: '2',
      },
    },
  },
  fonts: {
    heading: `'Open Sans', sans-serif`,
    body: `'Open Sans', sans-serif`,
  },
  fontSizes: {
    md: '1.5rem',
  },
})

const App = () => {
  return (
    <>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<Home />} />
              <Route path="apprenticeship" element={<Apprenticeship />} />
              <Route path="clients" element={<Clients />} />
              <Route path="privacy" element={<Privacy />} />
              <Route path="terms" element={<Terms />} />
              <Route path="support" element={<Support />} />
              <Route path="fulfillment" element={<Fulfillment />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </>
  )
}

export default App
