import { useState } from 'react'
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  StyleProps,
  Image,
} from '@chakra-ui/react'
import { Container, Heading, Link, Text } from '@chakra-ui/react'
import { atom, useAtom } from 'jotai'
import { Alert, AlertIcon } from '@chakra-ui/react'
import emailjs from '@emailjs/browser'
import { SplitView } from './Apprenticeship'

declare var gtag_report_conversion_lead: any
declare var fbq: any

const fullNameAtom = atom('')
const businessNameAtom = atom('')
const emailAtom = atom('')
const referralSourceAtom = atom('')
const needsAtom = atom('')

export const Clients = () => {
  const [fullName, setFullName] = useAtom(fullNameAtom)
  const [businessName, setBusinessName] = useAtom(businessNameAtom)
  const [email, setEmail] = useAtom(emailAtom)
  const [referralSource, setReferralSource] = useAtom(referralSourceAtom)
  const [needs, setNeeds] = useAtom(needsAtom)

  const [submitStatus, setSubmitStatus] = useState('')

  const sendEmail = async (formData: {
    fullName: string
    businessName: string
    email: string
    referralSource: string
    needs: string
  }) => {
    await emailjs.send(
      'service_y838puh',
      'client_leads',
      formData,
      'ioBqNBSRoF7e9h2BQ',
    )
  }

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const datatosend = { fullName, businessName, email, referralSource, needs }

    try {
      await sendEmail(datatosend)

      setFullName('')
      setBusinessName('')
      setEmail('')
      setReferralSource('')
      setNeeds('')
      setSubmitStatus('success')

      try {
        gtag_report_conversion_lead()
        fbq('track', 'Lead')
      } catch (e) {
        console.error(e)
      }
    } catch (error) {
      setSubmitStatus('error')
      console.error(error)
    } finally {
      setFullName('')
      setBusinessName('')
      setEmail('')
      setReferralSource('')
      setNeeds('')
    }
  }

  const isSuccess = submitStatus === 'success'
  const isError = submitStatus === 'error'

  return (
    <>
      <SplitView
        Left={(props: StyleProps) => (
          <Box {...props} backgroundColor="#F9FAFB">
            <Heading m={10} textAlign="center" size="2xl" marginTop="100px">
              US-based Coders Priced to Disrupt
            </Heading>
            <Text m={10} fontSize="lg">
              Code Correctly has developed an innovative pricing model that
              finally unlocks affordable access to software engineers in the
              United States.
            </Text>
          </Box>
        )}
        Right={(props: StyleProps) => (
          <Box {...props}>
            <Image src="cc-laptop.jpg" m="auto" />
          </Box>
        )}
      />
      <Container maxWidth="4xl">
        <Heading textAlign="center" mt={10}>
          Flat Upfront Pricing: $49 / hour
        </Heading>
        <Text textAlign="center">
          That's it. No fine print. No long term commitment.
        </Text>
        <Heading textAlign="center" mt={10}>
          How do you keep your prices so much lower than other development
          agencies?
        </Heading>
        <Text mx={10}>
          Highly skilled software engineers are expensive, sometimes billing out
          at more than $200 per hour. That may be fair when the engineer is
          doing complex, expertise-heavy work, but you are often paying the same
          cost no matter what - even when that same developer is centering text
          on a webpage. That's unfair to you and your investment. Code Correctly
          lowers the average cost of code at scale by investing in software
          engineers at every skill level from decades of experience to
          entry-level apprentices.
        </Text>
        <Heading textAlign="center" mt={10}>
          A little of what we do...
        </Heading>
        <Accordion>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Simple Websites to Complex Web Applications
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel>
              Do you need a few hours of help setting up a simple website? Or do
              you need a small engineering team to build a complex web
              application for your startup? Code Correctly's pricing model
              finally gives access to software engineers that previously
              wouldn't have been possible.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  App Development
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel>
              Do you have an app idea that you would like to see come to
              fruition? Not sure who to contact or how to get started? Code
              Correctly can help you take your app from just an idea to an app
              in the app store.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Twilio Flex Implementation Services
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel>
              Upgrading your contact center to Twilio Flex UI 2? Upgrading from
              Twilio Programmable Chat to Twilio Conversations? Code Correctly's
              lead engineer has led the initial implementation, as well as
              migration, of multiple Twilio Flex contact centers in production.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Heading mt={10} textAlign="center">
          Get in touch
        </Heading>
        <Text textAlign="center" mb={5} mx={10}>
          Interested in our devs working for you? Reach out below.
        </Text>
        <form onSubmit={handleSubmit}>
          <Flex align="center" justify="center">
            <Box borderWidth="1px" borderRadius="lg" p="6" width="md">
              <FormControl id="fullName" mb="4" isRequired>
                <FormLabel fontSize={'16px'}>Full Name</FormLabel>
                <Input
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </FormControl>
              <FormControl id="businessName" mb="4">
                <FormLabel fontSize={'16px'}>Business Name</FormLabel>
                <Input
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                />
              </FormControl>
              <FormControl id="email" mb="4" isRequired>
                <FormLabel fontSize={'16px'}>Email</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl id="referralSource" mb="4">
                <FormLabel fontSize={'16px'}>
                  How did you hear about us?
                </FormLabel>
                <Textarea
                  value={referralSource}
                  onChange={(e) => setReferralSource(e.target.value)}
                />
              </FormControl>
              <FormControl id="needs" mb="4" isRequired>
                <FormLabel fontSize={'16px'}>
                  Tell us more about your needs
                </FormLabel>
                <Textarea
                  value={needs}
                  onChange={(e) => setNeeds(e.target.value)}
                />
              </FormControl>
              <Button
                type="submit"
                backgroundColor={'#1D2939'}
                width={'100%'}
                textColor="white"
              >
                Send Message
              </Button>
              {isSuccess && (
                <Alert status="success" mt={5}>
                  <AlertIcon />
                  Thank you for contacting us, someone will get back to you soon
                  about your request.
                </Alert>
              )}
              {isError && (
                <Alert status="error">
                  <AlertIcon />
                  Something went wrong. Please try again or contact
                  <Link href="mailto:support@codecorrectly.com">
                    support@codecorrectly.com
                  </Link>
                  for assistance.
                </Alert>
              )}
            </Box>
          </Flex>
        </form>
        <Box mt={10}></Box>
      </Container>
    </>
  )
}
