import { Box, Center, Heading, Image, Text } from '@chakra-ui/react'

export const Home = () => {
  return (
    <>
      <Box backgroundColor={'#1D2939'} textColor="white">
        <Box margin="40px" marginTop={'0'}>
          <Heading color={'white'} size="2xl" width={'75%'}>
            We connect emerging dev talent to founders and, together, we build
            possibility
          </Heading>
          <Text width={'50%'}>
            Whether you're starting from scratch, or leveling-up an existing
            product, Code Correctly's mission is to fuel your success by
            delivering development talent without breaking the bank.
          </Text>
          <Center>
            <Image
              src="cc-window.png"
              // width="80%"
              padding={'40px'}
            />
          </Center>
        </Box>
      </Box>
    </>
  )
}
