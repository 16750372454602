import { Box, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <>
      <Box backgroundColor={'#1D2939'}>
        <Text textColor={'white'} padding="40px">
          © 2023 Code Correctly. All rights reserved.
        </Text>
        <Link to="/support">
          <Text textColor={'white'}>Support</Text>
        </Link>
        <Link to="/privacy">
          <Text textColor={'white'}>Privacy Policy</Text>
        </Link>
        <Link to="/terms">
          <Text textColor={'white'}>Terms of Service</Text>
        </Link>
        <Link to="/fulfillment">
          <Text textColor={'white'}>Fulfillment Policy</Text>
        </Link>
      </Box>
    </>
  )
}
