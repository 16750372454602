export const Fulfillment = () => {
  return (
    <>
      <h1>Fulfillment Policy</h1>
      <h2>Refund Policy</h2>Due to the nature of our hourly billing model for
      software development and services, we do not offer refunds. We ensure
      transparency and continuous communication with our clients throughout the
      development process to align with project goals and specifications.
      <h2>Delivery Policy</h2>
      Software projects or services are delivered via email upon completion and
      final review. Delivery timelines and milestones are outlined in the
      individual contract or statement of work.
      <h2>Cancellation Policy</h2>
      Clients may cancel their projects at any time; however, payment is
      required for any hours worked up to the point of cancellation. We
      recommend discussing any concerns with our team as early as possible to
      minimize any incurred costs. We are committed to delivering high-quality
      software solutions and maintaining open lines of communication with our
      clients to ensure project success and satisfaction.
    </>
  )
}
